export const setLocalStorageJson = (key: string, value: any) => {
  const item = {
    value,
  };
  try {
    const itemStr = JSON.stringify(item);
    localStorage.setItem(key, itemStr);
  } catch (error) {
    console.error(error);
  }
};

export const getLocalStorageJson = (key: string) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  try {
    const item = JSON.parse(itemStr);
    return item.value;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setLocalStorageWithExpiry = (
  key: string,
  value: any,
  numHours: number = 1,
) => {
  const ttl = 60 * 60 * 1000 * numHours;
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

interface GetLocalStorageWithExpiryOptions {
  returnNullIfMissingExpiry: boolean;
}

export const getLocalStorageWithExpiry = (
  key: string,
  options: GetLocalStorageWithExpiryOptions = {
    returnNullIfMissingExpiry: false,
  },
) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (
    (options.returnNullIfMissingExpiry && !item.expiry) ||
    now.getTime() > item.expiry
  ) {
    return null;
  }
  return item.value;
};

export const removeItemFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
