// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1-0 {\n  border: 2px solid #333;\n  display: flex;\n  padding: 12px;\n  gap: 12px;\n}\n\n._1-1 {\n  font-size: 14px;\n}\n\n._1-2 {\n  font-size: 12px;\n  color: #7c8d8f;\n}\n\n._1-3 {\n  background: black;\n  color: white;\n  border: none;\n  padding: 4px 12px;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n._1-4 {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n._1-5 {\n  justify-self: flex-end;\n  align-items: center;\n  margin-top: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n\n._1-6 {\n  flex: 0;\n}\n\n._1-7 {\n  margin: -2px -4px;\n  width: 50px;\n}\n.miniCartItem[data-pid=\"x-redo\"] {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/card-card.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,aAAa;AACf","sourcesContent":[".container {\n  border: 2px solid #333;\n  display: flex;\n  padding: 12px;\n  gap: 12px;\n}\n\n.title {\n  font-size: 14px;\n}\n\n.subtitle {\n  font-size: 12px;\n  color: #7c8d8f;\n}\n\n.button {\n  background: black;\n  color: white;\n  border: none;\n  padding: 4px 12px;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n.main {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.bottom {\n  justify-self: flex-end;\n  align-items: center;\n  margin-top: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.logo-container {\n  flex: 0;\n}\n\n.logo {\n  margin: -2px -4px;\n  width: 50px;\n}\n:global(.miniCartItem[data-pid=\"x-redo\"]) {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "_1-0";
export var title = "_1-1";
export var subtitle = "_1-2";
export var button = "_1-3";
export var main = "_1-4";
export var bottom = "_1-5";
export var logoContainer = "_1-6";
export var logo = "_1-7";
export default ___CSS_LOADER_EXPORT___;
