import "@redotech/redo-web/theme.css";
import { memo, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import { useInterval } from "usehooks-ts";
import { CartCard } from "./cart-card";
import { ExchangeSelectBanner } from "./exchange-select.module";

const App = memo(function App() {
  const [containers, setContainers] = useState<HTMLElement[]>([]);

  const bannerContainer = useMemo(() => {
    const element = document.createElement("div");
    element.id = "redo-banner";
    document.body.appendChild(element);
    return element;
  }, []);

  const exchangeSelect =
    sessionStorage.getItem("redo.exchange-select") !== null && opener;

  useInterval(() => {
    if (exchangeSelect) {
      return;
    }
    const result: HTMLElement[] = [];
    document.querySelectorAll(".miniCartItems").forEach((container) => {
      let redoContainer = container.querySelector(".redo-card") as HTMLElement;
      if (!redoContainer) {
        redoContainer = document.createElement("div");
        redoContainer.className = "redo-card";
        container.appendChild(redoContainer);
      }
      result.push(redoContainer);
    });
    setContainers(result);
  }, 100);

  return (
    <>
      {containers.map((container) => createPortal(<CartCard />, container))}
      {exchangeSelect &&
        createPortal(<ExchangeSelectBanner />, bannerContainer)}
    </>
  );
});

const startExchange = location.search.includes("redoExchangeSelect");
if (startExchange) {
  sessionStorage.setItem("redo.exchange-select", "");
}

const rootElement = document.createElement("div");
rootElement.id = "redo-commerce-cloud-widget";

const root = createRoot(rootElement);

root.render(<App />);
