import "./styles.css";
import "./styles.global.css";

import { IterableMap } from "@redotech/react-util/component";
import { StyleRoot } from "@redotech/react-util/style";
import { memo, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { useInterval } from "usehooks-ts";
import { CartCard } from "./cart-card";
import { ExchangeSelectBanner } from "./exchange-select.module";
import styleManager from "./style-manager";

const App = memo(function App() {
  const [containers, setContainers] = useState<HTMLElement[]>([]);

  const bannerContainer = useMemo(() => {
    const element = document.createElement("div");
    element.id = "redo-banner";
    document.body.appendChild(element);
    return element;
  }, []);

  const exchangeSelect =
    sessionStorage.getItem("redo.exchange-select") !== null && opener;

  useInterval(() => {
    if (exchangeSelect) {
      return;
    }
    const result: HTMLElement[] = [];
    document.querySelectorAll(".miniCartItems").forEach((container) => {
      let redoContainer = container.querySelector(".redo-card") as HTMLElement;
      if (!redoContainer) {
        redoContainer = document.createElement("div");
        redoContainer.className = "redo-card";
        container.appendChild(redoContainer);
      }
      result.push(redoContainer);
    });
    setContainers(result);
  }, 100);

  return (
    <>
      <IterableMap items={containers}>
        {(container) => (
          <StyleRoot container={container} styleManager={styleManager}>
            <CartCard />
          </StyleRoot>
        )}
      </IterableMap>
      {exchangeSelect && (
        <StyleRoot container={bannerContainer} styleManager={styleManager}>
          <ExchangeSelectBanner />
        </StyleRoot>
      )}
    </>
  );
});

const startExchange = location.search.includes("redoExchangeSelect");
if (startExchange) {
  sessionStorage.setItem("redo.exchange-select", "");
}

const rootElement = document.createElement("div");
rootElement.id = "redo-commerce-cloud-widget";

const root = createRoot(rootElement);

root.render(<App />);
