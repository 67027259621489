// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0-0 {\n  border: 2px solid #333;\n  display: flex;\n  padding: 12px;\n  gap: 12px;\n}\n\n._0-1 {\n  font-size: 14px;\n}\n\n._0-2 {\n  font-size: 12px;\n  color: #7c8d8f;\n}\n\n._0-3 {\n  background: black;\n  color: white;\n  border: none;\n  padding: 4px 12px;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n._0-4 {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n._0-5 {\n  justify-self: flex-end;\n  align-items: center;\n  margin-top: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n\n._0-6 {\n  margin: -2px -4px;\n  max-width: unset;\n  width: 50px;\n}\n\n._0-7 {\n}\n", "",{"version":3,"sources":["webpack://./src/card-card.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;AACA","sourcesContent":[".container {\n  border: 2px solid #333;\n  display: flex;\n  padding: 12px;\n  gap: 12px;\n}\n\n.title {\n  font-size: 14px;\n}\n\n.subtitle {\n  font-size: 12px;\n  color: #7c8d8f;\n}\n\n.button {\n  background: black;\n  color: white;\n  border: none;\n  padding: 4px 12px;\n  font-size: 12px;\n  cursor: pointer;\n}\n\n.main {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.bottom {\n  justify-self: flex-end;\n  align-items: center;\n  margin-top: 6px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.logo {\n  margin: -2px -4px;\n  max-width: unset;\n  width: 50px;\n}\n\n.logo-container {\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "_0-0";
export var title = "_0-1";
export var subtitle = "_0-2";
export var button = "_0-3";
export var main = "_0-4";
export var bottom = "_0-5";
export var logo = "_0-6";
export var logoContainer = "_0-7";
export default ___CSS_LOADER_EXPORT___;
