import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { memo, useEffect, useRef, useState } from "react";
import bgzLogo from "./bgz-logo.png?url";
import * as cartCard from "./card-card.module.css";
import { addItem, log, removeItem, updateQuantity, useCart } from "./util";

const STATE_KEY = "redo_enabled";

export const CartCard = memo(function CartCard() {
  const cart = useCart();
  const redoInCart = cart?.items.some((item) => item.brand === "re:do");
  const cartEligible = cart?.items
    .filter((item) => item.brand !== "re:do")
    .some((item) => !item.id.endsWith("R"));
  const [redoEnabled, setRedoEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    if (cart && redoEnabled === null) {
      setRedoEnabled(!!redoInCart);
    }
  }, [cartEligible, cart, redoInCart, redoEnabled]);

  const ensurePromiseRef = useRef<Promise<void>>();
  const ensureRedoLoad = useLoad(
    async (signal) => {
      if (!cart || redoEnabled === null) {
        return;
      }
      while (ensurePromiseRef.current) {
        try {
          await ensurePromiseRef.current;
        } catch (error) {
          // NOOP
        }
      }
      ensurePromiseRef.current = (async () => {
        const state = getLocalStorageWithExpiry(STATE_KEY);
        const shouldHaveRedo = cartEligible && (redoEnabled || state === null);
        log("Ensuring redo", {
          redoEnabled,
          cartEligible,
          shouldHaveRedo,
        });
        let redoFound = false;
        for (const item of cart?.items ?? []) {
          if (item.brand === "re:do") {
            redoFound = true;
            if (!shouldHaveRedo) {
              log("Removing redo", {
                item,
              });
              await removeItem(item, signal);
              if (redoEnabled && !cartEligible) {
                localStorage.removeItem(STATE_KEY);
              } else {
                setLocalStorageWithExpiry(STATE_KEY, "false");
              }
            } else if (item.quantity !== 1) {
              log("Setting redo quantity to 1", {
                item,
              });
              await updateQuantity(item, 1, signal);
            }
          }
        }
        if (shouldHaveRedo && !redoFound) {
          if (state === null) {
            log("adding redo");
            await addItem("x-redo", 1, signal);
            setLocalStorageWithExpiry(STATE_KEY, "true");
          } else if (state === "true") {
            log("redo removed");
            setLocalStorageWithExpiry(STATE_KEY, "false");
          }
        }
        (window as any).reloadCart?.();
      })();

      try {
        await ensurePromiseRef.current;
      } finally {
        ensurePromiseRef.current = undefined;
      }
    },
    [cart, redoEnabled, cartEligible],
  );

  const toggleState = useHandler(() => {
    if (ensureRedoLoad.pending) {
      return;
    }
    setRedoEnabled(!redoEnabled);
    if (!redoEnabled) {
      localStorage.removeItem(STATE_KEY);
    }
  });
  if (!cartEligible) {
    return null;
  }
  return (
    <div className={cartCard.container}>
      <div className={cartCard.logoContainer}>
        <img className={cartCard.logo} src={bgzLogo} />
      </div>
      <div className={cartCard.main}>
        <div className={cartCard.title}>Free and Easy Returns</div>
        <div className={cartCard.subtitle}>Free returns on your order</div>
        <div className={cartCard.bottom}>
          <div className={cartCard.feature}>$0.98</div>
          <button
            className={cartCard.button}
            disabled={ensureRedoLoad.pending}
            onClick={toggleState}
          >
            {redoEnabled ? "Remove" : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
});
