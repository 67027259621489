// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/commerce-cloud-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0-0 {\n  align-items: center;\n  background-color: #fffee0;\n  box-shadow: 0px 2px 8px rgba(127, 127, 127, 0.5);\n  display: flex;\n  justify-content: center;\n  left: 0;\n  padding: 14px;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 1000000;\n}\n\n._0-1 {\n  font-size: 16px;\n  font-weight: bold;\n  font-style: italic;\n  margin-right: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/exchange-select.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,gDAAgD;EAChD,aAAa;EACb,uBAAuB;EACvB,OAAO;EACP,aAAa;EACb,eAAe;EACf,QAAQ;EACR,MAAM;EACN,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".banner {\n  align-items: center;\n  background-color: #fffee0;\n  box-shadow: 0px 2px 8px rgba(127, 127, 127, 0.5);\n  display: flex;\n  justify-content: center;\n  left: 0;\n  padding: 14px;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 1000000;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: bold;\n  font-style: italic;\n  margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
export var banner = "_0-0";
export var title = "_0-1";
export default ___CSS_LOADER_EXPORT___;
