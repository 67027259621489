import { useLoad } from "@redotech/react-util/load";
import { httpResponseEvents } from "@redotech/web-util/http";
import * as isEqual from "lodash/isEqual";
import { useSubscription } from "observable-hooks";
import { useState } from "react";
import { useDebounce } from "usehooks-ts";

window.redoDebug = (enabled: boolean) => {
  sessionStorage.setItem("redo-debug", String(enabled));
};

export function log(...args: any[]) {
  if (sessionStorage?.getItem("redo-debug") === "true") {
    console.log(...args);
  }
}

type CartItem = {
  id: string;
  quantity: number;
  brand?: string;
  UUID: string;
};

type Cart = {
  items: CartItem[];
};

export async function getCart(signal?: AbortSignal): Promise<Cart> {
  const response = await fetch(
    "/on/demandware.store/Sites-BodyGuardz-Site/en_US/Cart-Get",
    {
      signal,
    },
  );
  return { items: (await response.json())?.items ?? [] };
}

export async function addItem(
  id: string,
  quantity: number,
  signal?: AbortSignal,
): Promise<void> {
  const formData = new FormData();
  formData.append("pid", id);
  formData.append("quantity", String(quantity));
  await fetch(
    "/on/demandware.store/Sites-BodyGuardz-Site/en_US/Cart-AddProduct",
    {
      method: "POST",
      signal,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        pid: id,
        quantity: quantity.toString(),
      }),
    },
  );
}

export async function removeItem(
  item: CartItem,
  signal?: AbortSignal,
): Promise<void> {
  const params = new URLSearchParams();
  params.append("pid", item.id);
  params.append("uuid", item.UUID);
  await fetch(
    `/on/demandware.store/Sites-BodyGuardz-Site/en_US/Cart-RemoveProductLineItem?${params.toString()}`,
    {
      signal,
    },
  );
}
export async function updateQuantity(
  item: CartItem,
  quantity: number,
  signal?: AbortSignal,
): Promise<void> {
  const params = new URLSearchParams();
  params.append("pid", item.id);
  params.append("uuid", item.UUID);
  params.append("quantity", String(quantity));
  await fetch(
    `/on/demandware.store/Sites-BodyGuardz-Site/en_US/Cart-UpdateQuantity?${params.toString()}`,
    {
      signal,
    },
  );
}

const isSfccCartUrl = (url: string) =>
  /\/cart-[^g]/i.test(url) &&
  !url.includes("api.getredo.com") &&
  !url.includes("localhost");

export function useCart(manualRefresh?: Symbol) {
  const [cart, setCart] = useState<Cart | undefined>(undefined);
  const [cartRecheckSymbol, recheckCart] = useState(Symbol());
  const cartRecheck = useDebounce(cartRecheckSymbol, 500);

  useSubscription(httpResponseEvents, ({ request }) => {
    if (isSfccCartUrl(request.url)) {
      recheckCart(Symbol());
    }
  });

  useLoad(
    async (signal) => {
      for (let i = 0; i < 2; i++) {
        log("fetching cart");
        const newCart = await getCart(signal);
        if (!cart || !isEqual(cart, newCart)) {
          log("found new cart", { newCart });
          setCart(newCart);
          if (newCart.items.length) {
            break;
          }
        } else {
          log("cart unchanged", { cart: newCart });
        }
      }
    },
    [cartRecheck, manualRefresh],
  );

  return cart;
}
